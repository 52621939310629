import { env } from "../config/env";
import { api } from "./api";
import { endpointConfig } from "../config/api";
import { ui } from "./ui";
import { StdErr } from "../lib/types/miscTypes";
import { computed, IObservableArray, observable } from "mobx";
import { $11, CNErrorReport, CNScore2, CreditReport, CreditReportPullDTO } from "../lib/types/dataTypes";
import { getDisplayName } from "../utils/helpers";
import ALPHONSO_TESTEVANS from "../config/api/mock/ALPHONSO_TESTEVANS.xml.js";
import { parseString } from "xml2js";
import { Controller } from "../lib/controller";

export interface EquifaxStore {
  creditReports: CreditReport[];
}

export class Equifax extends Controller<EquifaxStore> {
  @observable passwordEntered: boolean = false;

  @observable loading: boolean;
  @observable searchValue: string = "";
  @observable viewSelectedAccounts = {};
  @observable lastManualPullEntry: CreditReportPullDTO = {} as CreditReportPullDTO;

  @computed get creditReports(): IObservableArray<CreditReport> {
    this.storage.initProperty("creditReports", []);
    return this.store.creditReports as IObservableArray<CreditReport>;
  }

  showError = (err: StdErr) => ui.showError({ err, actionName: "Get Imported Credit Reports" });

  loadAllData = async () => {
    parseString(ALPHONSO_TESTEVANS, { trim: true }, (err, result) => {
      !this.creditReports.some(r => this.getId(r) === this.getId(result))
      && this.store.creditReports.push(result as CreditReport)
    });
  }

  // sortData = (data: BsImportFormat[]) => (data || []).sort((a: CreditReport, b: CreditReport) => (
  //   -((a.date ? new Date(a.date).getTime() : 0) -
  //     (b.date ? new Date(b.date).getTime() : 0))
  // ));

  getConsumerStatements = (creditReport: CreditReport) =>
    ((((creditReport.EfxTransmit?.EfxReport || [])[0]?.CNConsumerCreditReports || [])[0]?.CNConsumerCreditReport || [])[0]?.CNConsumerStatements || [])[0];

  getHeader = (creditReport: CreditReport) =>
    ((((creditReport.EfxTransmit?.EfxReport || [])[0]?.CNConsumerCreditReports || [])[0]?.CNConsumerCreditReport || [])[0]?.CNHeader || [])[0];

  getAddresses = (creditReport: CreditReport) =>
    ((((creditReport.EfxTransmit?.EfxReport || [])[0]?.CNConsumerCreditReports || [])[0]?.CNConsumerCreditReport || [])[0]?.CNAddresses || [])[0];

  getScores = (creditReport: CreditReport) =>
    ((((creditReport.EfxTransmit?.EfxReport || [])[0]?.CNConsumerCreditReports || [])[0]?.CNConsumerCreditReport || [])[0]?.CNScores || [])[0];

  getEmployments = (creditReport: CreditReport) =>
    ((((creditReport.EfxTransmit?.EfxReport || [])[0]?.CNConsumerCreditReports || [])[0]?.CNConsumerCreditReport || [])[0]?.CNEmployments || [])[0];

  getTrades = (creditReport: CreditReport) =>
    (((creditReport.EfxTransmit?.EfxReport || [])[0]?.CNConsumerCreditReports || [])[0]?.CNConsumerCreditReport || [])[0]?.CNTrades || [];

  getLocalInq = (creditReport: CreditReport) =>
    ((((creditReport.EfxTransmit?.EfxReport || [])[0]?.CNConsumerCreditReports || [])[0]?.CNConsumerCreditReport || [])[0]?.CNLocalInquiries || [])[0];

  getForeignInq = (creditReport: CreditReport) =>
    ((((creditReport.EfxTransmit?.EfxReport || [])[0]?.CNConsumerCreditReports || [])[0]?.CNConsumerCreditReport || [])[0]?.CNForeignInquiries || [])[0];

  getId = (creditReport: CreditReport) =>
    ((this.getHeader(creditReport)?.CreditFile || [])[0]?.UniqueNumber || [])[0];

  getClientName = (creditReport: CreditReport): string =>
    getDisplayName({
      FirstName: (this.getHeader(creditReport)?.Subject || [])[0]?.SubjectName[0]?.FirstName[0],
      LastName: (this.getHeader(creditReport)?.Subject || [])[0]?.SubjectName[0]?.LastName[0]
    });

  getReportDate = (creditReport: CreditReport) =>
    this.getHeader(creditReport)?.CreditFile[0]?.DateOfRequest[0];

  getReportSinceDate = (creditReport: CreditReport) =>
    this.getHeader(creditReport)?.CreditFile[0]?.FileSinceDate[0];

  getFileLastActivityDate = (creditReport: CreditReport) =>
    this.getHeader(creditReport)?.CreditFile[0]?.DateOfLastActivity[0];

  getConsumerStatementInfo = (creditReport: CreditReport) => ({
    date: this.getConsumerStatements(creditReport)?.CNConsumerStatement[0]?.DateReported[0],
    info: this.getConsumerStatements(creditReport)?.CNConsumerStatement[0]?.Statement[0]
  });

  getEmploymentType = (employment: $11) => ({
    ES: "Current Employment",
    EF: "Former Employment",
    E2: "Second Former Employment"
  })[employment.code];

  getScoreString = (score: CNScore2) => {
    const result = Number((score.Result || [])[0]?.Value[0]);
    return isNaN(result) ? "Unavailable" : result;
  };

  fetchManualReport = async (data: CreditReportPullDTO): Promise<string> => {
    this.loading = true;
    this.lastManualPullEntry = data;
    return api.POST({
      endpoint: endpointConfig.manually_fetch_report,
      data
    })
    .then(response => new Promise((resolve, reject) => parseString(response.data, { trim: true }, (err, result) => {
      if (err) reject(err);
      resolve(result);
    })))
    .then((report: CreditReport) => {
      const errorReport = (report.EfxTransmit.CNErrorReport || [])[0];
      if (errorReport) {
        ui.alert({
          header: "Error",
          message: this.parseCNErrorMessage(errorReport),
          buttons: ["Confirm"]
        });
        return null;
      }
      const noReport = !this.getId(report);
      if (noReport) {
        ui.alert({
          header: "No Hit",
          message: "No matching credit report",
          buttons: ["Confirm"]
        });
        return null;
      }
      const existing = this.creditReports.find(r => this.getId(r) === this.getId(report));
      if (existing) Object.assign(existing, report);
      else this.store.creditReports.push(report);
      return this.getId(report);
    })
    .finally(() => this.loading = false);
  };

  parseCNErrorMessage = (errorReport: CNErrorReport): string => {
    const { Errors } = errorReport;
    return (Errors || [])[0]?.Error.map(err => err.Description).join("<br />");
  };

  handleSearchChange = (event: any) => {
    const value = event.target.value || event.detail.value;
    return this.searchValue = value;
  }

  verifyIframeToken = (token: string): Promise<boolean> => api.POST({
    endpoint: endpointConfig.verify_iframe_token,
    data: { token }
  })
  .then(response => {
    const pass = !!response.data;
    if (pass) return this.passwordEntered = true;
    return Promise.reject();
  })
  .catch(err => {
    console.error(err);
    return Promise.reject();
  });

  verifyPassword = (username: string, password: string) => api.POST({
    endpoint: endpointConfig.login_user_pass,
    data: { username, password }
  })
  .then(response => {
    const pass = !!response.data;
    if (pass) this.passwordEntered = true;
    return pass;
  })
  .catch(err => {
    console.error(err);
    return Promise.resolve(false);
  });
}

export const equifax = new Equifax();

if (window && env as string !== "prod") (window as any).equifax = equifax;
