import React from "react";
import { IonApp, IonLoading, IonRouterOutlet, IonSplitPane, setupConfig } from "@ionic/react";
import { observer } from "mobx-react";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./config/styles/ionic/variables.css";
import "./config/styles/global.css";
/* App Modules */
import { UI, ui } from "./client/ui";
import Home from "./pages/Home";
import { IonReactRouter } from "@ionic/react-router";
import Sidebar from "./components/Sidebar";
import { Route } from "react-router";
import { Api, api } from "./client/api";
import { equifax, Equifax } from "./client/equifax";
import { computed, observable, reaction } from "mobx";
import PopoverContainer from "./components/PopoverContainer";
import CreditReportPage from "./pages/CreditReport";
import { getQueryParameters } from "./utils/helpers";
import FullScreenSpinner from "./components/FullScreenSpinner";

// To keep platform styling consistency, set all styling mode to Material.
setupConfig({
  mode: "md"
});

@observer
class App extends React.Component {
  @observable appId: number;

  @observable sidebarLoading: boolean = false;
  @observable sidebarLoadingMore: boolean = false;
  @observable sidebarLoadingAll: boolean = false;

  @computed get ready(): boolean {
    return this.equifax && this.equifax.storage.ready;
  }

  ui: UI;
  api: Api;
  equifax: Equifax;

  constructor(props) {
    super(props);
    this.ui = ui;
    this.api = api;
    this.equifax = equifax;
    reaction(() => !!equifax.passwordEntered, (effect, reaction) => {
      reaction.dispose();
      return !this.appId && this.onRefresh(null);
    });
  };

  componentDidMount(): void {
    setTimeout(this.onLoad);
  }

  onLoad = async () => {
    const { search } = window.location;
    const inputs = getQueryParameters(search);
    const keys = Object.keys(inputs);
    for (const key of keys) {
      if (key === "hideSidebar") {
        ui.sidebarDisabled = true;
      }
      if (key === "appId") {
        this.appId = isNaN(Number(inputs[key]))
        ? null
        : Number(inputs[key])
      }
      if (key === "token") {
        equifax.verifyIframeToken(inputs[key]).catch(this.popupPassword);
      }
    }
    if (!keys.includes("token")) return this.popupPassword();
  }

  onRefresh = (event: any) => {
    this.sidebarLoading = true;
    equifax.searchValue = "";
    return equifax.loadAllData()
    .finally(() => this.sidebarLoading = false);
  }

  // onGetAll = (event: any) => {
  //   this.sidebarLoadingAll = true;
  //   return equifax.getAllData()
  //   .finally(() => this.sidebarLoadingAll = false);
  // }
  //
  // onGetMore = (event: any) => {
  //   this.sidebarLoadingMore = true;
  //   return equifax.getMoreData()
  //   .finally(() => this.sidebarLoadingMore = false);
  // }

  popupPassword = () => {
    const handler = async data => {
      const { username, password } = data;
      const result = await equifax.verifyPassword(username, password);
      if (!result) return setTimeout(popup);
    };
    const popup = () => {
      return ui.alert({
        header: "Login",
        inputs: [
          {
            name: "username",
            type: "text",
            placeholder: "Username"
          },
          {
            name: "password",
            type: "password",
            placeholder: "Password"
          }
        ],
        backdropDismiss: false,
        buttons: [{
          text: "Confirm",
          handler
        }]
      })
      .then(alert => {
        const confirmButton = document.querySelectorAll("ion-alert button")[0];
        if (!confirmButton) return;
        const exec = async e => {
          if (e.key !== "Enter") return;
          window.removeEventListener("keypress", exec);
          (confirmButton as HTMLIonButtonElement).click();
          return alert.dismiss();
        };
        window.addEventListener("keypress", exec);
      });
    }
    return popup();
  }

  render() {
    return (
      <IonApp>
        <IonLoading isOpen={equifax.loading} />
        <IonReactRouter>
          {(!equifax.passwordEntered || !this.ready) ? (
            <FullScreenSpinner size={40} color="primary" />
          ) : (
            <IonSplitPane contentId="main">
              <Sidebar
                disabled={ui.sidebarDisabled}
                loading={this.sidebarLoading}
                loadingMore={this.sidebarLoadingMore}
                loadingAll={this.sidebarLoadingAll}
                creditReports={equifax.creditReports}
                searchValue={equifax.searchValue}
                onSearchChange={equifax.handleSearchChange}
                onRefresh={this.onRefresh}
                // onGetAll={this.onGetAll}
                // onGetMore={this.onGetMore}
              />
              <IonRouterOutlet id="main">
                <Route exact path="/" component={Home} />
                <Route exact path="/:id" component={CreditReportPage} />
              </IonRouterOutlet>
              {/*<AppIdEntryHandler appId={this.appId} />*/}
            </IonSplitPane>
          )}
        </IonReactRouter>
        {/* Global popover menu container */}
        <PopoverContainer ui={ui} />
      </IonApp>
    );
  }
}

export default App;
