import "mobx-react-lite/batchingForReactDom";
import React from "react";
import { Observer, observer } from "mobx-react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonRippleEffect,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { computed, observable, reaction } from "mobx";
import { StdErr } from "../../lib/types/miscTypes";
import { ui } from "../../client/ui";
import { CreditReport, DbUser } from "../../lib/types/dataTypes";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import { getDisplayName, getPriceString, isEmpty } from "../../utils/helpers";
import "./styles.css";
import Accordion from "../../components/Accordion";
import { chevronDownSharp, chevronUpSharp, trashOutline } from "ionicons/icons";
import { AccordionController } from "../../lib/accordion";
import { equifax } from "../../client/equifax";

const AccountSectionHeader = observer(({ isExpanded, title }) => (
  <div className="flex ion-justify-content-between ion-align-items-center ion-padding relative accountSectionHeader">
    <IonText color="primary" className="font-m">
      {title}
    </IonText>
    <IonIcon
      className="font-m"
      icon={isExpanded ? chevronUpSharp : chevronDownSharp}
      color="primary"
    />
    <IonRippleEffect />
  </div>
))

export interface CreditReportPageProps extends RouteComponentProps<{
  id: string
}> {}

@observer
class CreditReportPage extends React.Component<CreditReportPageProps> {
  accordionController: AccordionController;

  overviewRef = elm => this.overviewElm = elm;
  overviewElm: Accordion;

  employmentRef = elm => this.employmentElm = elm;
  employmentElm: Accordion;

  scoresRef = elm => this.scoresElm = elm;
  scoresElm: Accordion;

  localInquiriesRef = elm => this.localInquiriesElm = elm;
  localInquiriesElm: Accordion;

  foreignInquiriesRef = elm => this.foreignInquiriesElm = elm;
  foreignInquiriesElm: Accordion;

  tradesRef = elm => this.tradesElm = elm;
  tradesElm: Accordion;

  url: string;
  @observable id: string = "";
  @observable refreshing: boolean = false;
  @observable addressLoading: boolean = true;

  @observable application = {};
  @observable applicationStatusLoading: boolean = false;

  @observable applicationUser: DbUser = {} as DbUser;
  @observable applicationUserLoading: boolean = false;

  @observable alertAddressMismatch: boolean = true;

  @computed get loading(): boolean {
    return isEmpty(this.data);
  };
  @computed get data(): CreditReport {
    const report = equifax.creditReports.find(cr => Number(equifax.getId(cr)) === Number(this.id));
    return report || {} as CreditReport;
  };
  @computed get clientName(): string {
    return equifax.getClientName(this.data);
  };
  @computed get consumerStatementInfo() {
    return equifax.getConsumerStatementInfo(this.data);
  };

  @computed get alertNameMismatch(): boolean {
    if (isEmpty(this.applicationUser) || isEmpty(this.data)) return true;
    const displayName = getDisplayName(this.applicationUser);
    const clientName = this.clientName || "";
    if (!displayName || !clientName) return true;
    const displayNameRegex = new RegExp(displayName.replace(" ", "|"), "ig");
    const clientNameRegex = new RegExp(clientName.replace(" ", "|"), "ig");
    return !displayName.match(clientNameRegex) && !clientName.match(displayNameRegex);
  };

  @computed get applicationCompleted(): boolean {
    return this.application && !!this.application["user_flow_finished_date"];
  };

  constructor (props) {
    super(props);
    this.accordionController = new AccordionController("creditReport", false);
    this.url = props.match.url;
    // autorun(() =>
    //   this.id &&
    //   !isEmpty(this.accounts) &&
    //   (isEmpty(this.selectedAcc) || isEmpty(this.currentAccount)) &&
    //   (inverite.viewSelectedAccounts[this.id] = this.accounts[0].accountNumber)
    // );
    // autorun(this.checkAlertAddressMismatch);
    // reaction(() => this.data && this.data.appId, this.getApplication);
    // reaction(() => this.data && this.data.appId, this.getApplicationUser);
    reaction(() => this.data && this.clientName, () => (
      document.getElementsByTagName("title")[0].innerHTML = `${this.clientName || this.id} | Finjoy Inverite Data`
    ));
    // reaction(() => this.data, this.getLoanOffers);
  }

  ionViewWillEnter = () => {
    const { id } = this.props.match.params;
    this.id = id;
    if (isEmpty(this.data)) return this.props.history.push("/");
  };

  showError = (err: StdErr) => ui.showError({ err, actionName: this.id });

  // recursivelyGetMore = async () => isEmpty(this.data) ? inverite.getMoreData().then(this.recursivelyGetMore) : Promise.resolve();

  // onRefresh = (event: any) => {
  //   this.refreshing = true;
  //   return inverite.refreshVerification(this.id)
  //   .then(this.getApplicationUser)
  //   .catch(this.showError)
  //   .finally(() => this.refreshing = false);
  // };

  // checkAlertAddressMismatch = async () => {
  //   if (isEmpty(this.applicationUser) || isEmpty(this.data)) return true;
  //   this.addressLoading = true;
  //   let clientAddress = this.data.clientAddress;
  //   let full_address = this.applicationUser.full_address;
  //
  //   const getClientAddressId = () => api.GET(endpointConfig.google_places_search(clientAddress))
  //   .then(response => {
  //     const { candidates } = response.data;
  //     const { place_id } = (candidates || [])[0] || {};
  //     return place_id;
  //   });
  //
  //   const getFullAddressId = () => api.GET(endpointConfig.google_places_search(full_address))
  //   .then(response => {
  //     const { candidates } = response.data;
  //     const { place_id } = (candidates || [])[0] || {};
  //     return place_id;
  //   });
  //
  //   const [clientAddressId, fullAddressId] = await Promise.all([
  //     getClientAddressId(), getFullAddressId()
  //   ]).catch(console.error);
  //
  //   if (clientAddressId !== fullAddressId) {
  //     const getClientAddressCoords = () => api.GET(endpointConfig.google_geocoding_search(clientAddress))
  //     .then(response => response.data.results);
  //     const getFullAddressCoords = () => api.GET(endpointConfig.google_geocoding_search(full_address))
  //     .then(response => response.data.results);
  //     const [clientAddressCoords, fullAddressCoords] = await Promise.all([
  //       getClientAddressCoords(), getFullAddressCoords()
  //     ]).catch(console.error);
  //
  //     this.alertAddressMismatch = !(clientAddressCoords || []).some(coord1 => {
  //       const { geometry } = coord1 || {};
  //       const { location } = geometry || {};
  //       const lat1 = (location || {}).lat;
  //       const lng1 = (location || {}).lng;
  //       return (fullAddressCoords || []).some(coord2 => {
  //         const { geometry } = coord2 || {};
  //         const { location } = geometry || {};
  //         const lat2 = (location || {}).lat;
  //         const lng2 = (location || {}).lng;
  //         return lat1 === lat2 && lng1 === lng2;
  //       });
  //     });
  //   } else {
  //     this.alertAddressMismatch = false;
  //   }
  //   // await new Promise(resolve => setTimeout(resolve, 200));
  //   return this.addressLoading = false;
  // };

  // getApplication = () => {
  //   if (isEmpty(this.data)) return;
  //   this.applicationStatusLoading = true;
  //   return inverite.getLoanApplicationByInveriteId(this.id)
  //   .then(app => this.application = app)
  //   .catch(this.showError)
  //   .finally(() => this.applicationStatusLoading = false);
  // };

  // getApplicationUser = async () => {
  //   if (isEmpty(this.data)) return;
  //   this.applicationUserLoading = true;
  //   return inverite.getLoanApplicationUserByAppId(Number(this.data.appId))
  //   .then(user => this.applicationUser = user)
  //   .catch(this.showError)
  //   .finally(() => this.applicationUserLoading = false);
  // };

  handleAccordionClick = (event: any, section: string) => {
    const isExpanded = this.accordionController.isAccordionExpanded(section);
    return this.accordionController.onAccordionFold(section, !isExpanded);
  };

  handleDelete = () => {
    const handler = () => {
      this.props.history.push("/");
      return equifax.creditReports.remove(this.data);
    };
    return ui.alert({
      header: "Delete report",
      message: "Are you sure you would like to delete this credit report?",
      buttons: [
        {
          role: "cancel",
          text: "No",
        },
        {
          handler,
          text: "Yes"
        }
      ]
    })
  };

  renderSummary = () => <Observer>{() => (
    <div className="flex ion-justify-content-between ion-wrap summary">
      <div className="flex column">
        <IonText className="textBold">
          Applicant:&nbsp;{this.clientName || <IonText className="textItalic" color="medium">Unknown client name</IonText>}
        </IonText>
        <IonText>
          App ID:&nbsp;XXXXXX
        </IonText>
        <IonText>
          Application Status:&nbsp;
          <IonText color={this.applicationCompleted ? "success" : "warning"}>{
            this.applicationStatusLoading
              ? <IonSpinner color="primary" style={{ width: "15px", height: "15px" }} />
              : (this.applicationCompleted ? "Completed" : "Incomplete")}
          </IonText>
        </IonText>
        <IonText>
          Submission date:&nbsp;{equifax.getReportDate(this.data)}
        </IonText>
      </div>
      <div className="flex column">
        <IonText>
          Date File Opened:&nbsp;{equifax.getReportSinceDate(this.data)}
        </IonText>
        <IonText>
          Date of Last Activity:&nbsp;{equifax.getFileLastActivityDate(this.data)}
        </IonText>
        <IonText>
          Unique Number:&nbsp;{this.id}
        </IonText>
        <IonText>
          Hit strength:&nbsp;<span className="textPrimary textBold">
            {((equifax.getHeader(this.data)?.CreditFile || [])[0].HitStrengthIndicator || [])[0].$.description}
          </span>
        </IonText>
      </div>
    </div>
  )}</Observer>

  renderOverview = () => <Observer>{() => (
    <Accordion
      ref={this.overviewRef}
      duration={200}
      className="ion-padding accountSection overview"
      backgroundColor="#ffffff00"
      headerComponent={
        <AccountSectionHeader
          title="Overview"
          isExpanded={!this.accordionController.isAccordionExpanded("overview")}
        />
      }
      expanded={!this.accordionController.isAccordionExpanded("overview")}
      onClick={e => this.handleAccordionClick(e, "overview")}
    >
      <div className="flex column">
        <IonCard>
          <IonCardContent>
            <IonList className="ion-no-padding">
              <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                Client information
              </IonListHeader>
              <div className="flex half ion-align-items-end">
                <IonItem lines="full">
                  <IonLabel position="stacked" className="textPrimary">
                    First name
                  </IonLabel>
                  <IonText className="textBold">
                    {(equifax.getHeader(this.data)?.Subject || [])[0]?.SubjectName[0]?.FirstName[0] || "-"}
                  </IonText>
                </IonItem>
                <IonItem lines="full">
                  <IonLabel position="stacked" className="textPrimary">
                    Last name
                  </IonLabel>
                  <IonText className="textBold">
                    {(equifax.getHeader(this.data)?.Subject || [])[0]?.SubjectName[0]?.LastName[0] || "-"}
                  </IonText>
                </IonItem>
              </div>
              <div className="flex half ion-align-items-end">
                <IonItem lines="full">
                  <IonLabel position="stacked" className="textPrimary">
                    Date of birth
                  </IonLabel>
                  <IonText>
                    {((equifax.getHeader(this.data)?.Subject || [])[0]?.SubjectId || [])[0]?.DateOfBirth[0]._ || "-"}
                  </IonText>
                </IonItem>
                <IonItem lines="full">
                  <IonLabel position="stacked" className="textPrimary">
                    SIN
                  </IonLabel>
                  <IonText>
                    {(((equifax.getHeader(this.data)?.Subject || [])[0]?.SubjectId || [])[0]?.SocialInsuranceNumber || [])[0] || "-"}
                  </IonText>
                </IonItem>
              </div>
            </IonList>


            <IonList className="ion-no-padding">
              <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                Addresses
              </IonListHeader>
              {equifax.getAddresses(this.data)?.CNAddress.map(address => <React.Fragment key={address.$.description}>
                <IonItem lines="none">
                  <IonLabel position="stacked" className="textPrimary">
                    {address?.$.description}
                  </IonLabel>
                  <IonText>
                    {(address?.CivicNumber || [])[0]}&nbsp;
                    {(address?.StreetName || [])[0]},&nbsp;
                    {(address?.City || [])[0]?._},&nbsp;
                    {(address?.Province || [])[0]?.$.code},&nbsp;
                    {(address?.PostalCode || [])[0]}
                  </IonText>
                </IonItem>
                <div className="flex half ion-align-items-end">
                  <IonItem lines="full">
                    <IonLabel position="stacked" className="textPrimary">
                      Since, R/O/B:
                    </IonLabel>
                    <IonText>
                      {address?.DateReported}
                    </IonText>
                  </IonItem>
                  <IonItem lines="full">
                    <IonLabel position="stacked" className="textPrimary">
                      Reported
                    </IonLabel>
                    <IonText>
                      {((address?.UpdateSource || [])[0]?.$?.description || "").replace(/rptd/g, "Reported")}
                    </IonText>
                  </IonItem>
                </div>
              </React.Fragment>)}
            </IonList>


            {(this.alertNameMismatch || this.alertAddressMismatch) && (
              <IonList className="ion-no-padding">
                <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                  Alerts
                </IonListHeader>
                {this.applicationUserLoading ? (
                  <div className="flex ion-align-items-center ion-justify-content-center">
                    <IonSpinner color="primary" />
                  </div>
                ) : <>
                  {this.alertNameMismatch && (
                    <div className="flex half ion-align-items-end">
                      <IonItem lines="full">
                        <IonLabel position="stacked" className="textPrimary">
                          Name mismatch
                        </IonLabel>
                        <IonText color={this.alertNameMismatch ? "danger" : undefined}>
                          {this.alertNameMismatch ? "Yes" : "No"}
                        </IonText>
                      </IonItem>
                      <IonItem lines="full">
                        <IonLabel position="stacked" className="textPrimary">
                          Name on application
                        </IonLabel>
                        <IonText>
                          {getDisplayName(this.applicationUser)}
                        </IonText>
                      </IonItem>
                    </div>
                  )}
                  {/*{this.alertAddressMismatch && (*/}
                  {/*  <div className="flex half ion-align-items-end">*/}
                  {/*    <IonItem lines="full">*/}
                  {/*      <IonLabel position="stacked" className="textPrimary">*/}
                  {/*        Address mismatch*/}
                  {/*      </IonLabel>*/}
                  {/*      <IonText color={(!this.addressLoading && this.alertAddressMismatch) ? "danger" : undefined}>*/}
                  {/*        {this.addressLoading ? "..." : this.alertAddressMismatch ? "Yes" : "No"}*/}
                  {/*      </IonText>*/}
                  {/*    </IonItem>*/}
                  {/*    <IonItem lines="full">*/}
                  {/*      <IonLabel position="stacked" className="textPrimary">*/}
                  {/*        Address on application*/}
                  {/*      </IonLabel>*/}
                  {/*      <IonText>*/}
                  {/*        {this.applicationUser.full_address}*/}
                  {/*      </IonText>*/}
                  {/*    </IonItem>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </>}
              </IonList>
            )}

            {!isEmpty(this.consumerStatementInfo?.info) && (
              <IonList className="ion-no-padding">
                <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                  * Additional information from Equifax
                </IonListHeader>
                <IonItem>
                  <IonLabel position="floating" className="textPrimary">
                    Date: {this.consumerStatementInfo.date}
                  </IonLabel>
                  <IonTextarea
                    autoGrow
                    readonly
                    value={this.consumerStatementInfo.info}
                  />
                </IonItem>
              </IonList>
            )}
          </IonCardContent>
        </IonCard>
      </div>
    </Accordion>
  )}</Observer>;

  renderScores = () => <Observer>{() => (
    <Accordion
      ref={this.scoresRef}
      duration={200}
      className="ion-padding accountSection scores"
      backgroundColor="#ffffff00"
      headerComponent={
        <AccountSectionHeader
          title="Scores"
          isExpanded={this.accordionController.isAccordionExpanded("scores")}
        />
      }
      expanded={this.accordionController.isAccordionExpanded("scores")}
      onClick={e => this.handleAccordionClick(e, "scores")}
    >
      <div className="flex column">
        <IonCard>
          <IonCardContent>
            {equifax.getScores(this.data)?.CNScore
            .map(score => {
              const normalReasons = (score.Reasons || [])[0]?.Reason.map(reason => reason.$.description).join("\n");
              const rejectReasons = (score.RejectCodes || [])[0]?.RejectCode.map(reason => reason.$.description).join("\n");
              return (
                <IonList className="ion-no-padding" key={score.$?.description}>
                  <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                    {score.$?.description}
                  </IonListHeader>
                  <IonItem lines="full">
                    <IonLabel position="stacked" className="textPrimary">
                      Score
                    </IonLabel>
                    <IonText className="textBold" color="secondary">
                      {equifax.getScoreString(score)}
                    </IonText>
                  </IonItem>
                  <IonItem lines="full">
                    <IonLabel position="floating" className="textPrimary">
                      Reason(s)
                    </IonLabel>
                    <IonTextarea
                      autoGrow
                      readonly
                      value={(normalReasons || "").trim() || (rejectReasons || "").trim()}
                    />
                  </IonItem>
                </IonList>
              )
            })}
          </IonCardContent>
        </IonCard>
      </div>
    </Accordion>
  )}</Observer>;

  renderEmployments = () => <Observer>{() => (
    <Accordion
      ref={this.employmentRef}
      duration={200}
      className="ion-padding accountSection employments"
      backgroundColor="#ffffff00"
      headerComponent={
        <AccountSectionHeader
          title="Employments"
          isExpanded={this.accordionController.isAccordionExpanded("employments")}
        />
      }
      expanded={this.accordionController.isAccordionExpanded("employments")}
      onClick={e => this.handleAccordionClick(e, "employments")}
    >
      <div className="flex column">
        <IonCard>
          <IonCardContent>
            <Observer>{() => {
              const employment = equifax.getEmployments(this.data);
              return !isEmpty(employment) ? <>{employment?.CNEmployment.map(emp => (
                <IonList className="ion-no-padding" key={emp.$.code}>
                  <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                    {equifax.getEmploymentType(emp.$)}
                  </IonListHeader>
                  <div className="flex half ion-align-items-end">
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Description
                      </IonLabel>
                      <IonText>
                        {emp.$.description}
                      </IonText>
                    </IonItem>
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Since
                      </IonLabel>
                      <IonText>
                        {(emp.Occupation || [])[0]}
                      </IonText>
                    </IonItem>
                  </div>
                </IonList>
              ))}</> : <IonText className="ion-margin-vertical" color="primary">No employments</IonText>
            }}</Observer>
          </IonCardContent>
        </IonCard>
      </div>
    </Accordion>
  )}</Observer>;

  renderTradeAccounts = () => <Observer>{() => (
    <Accordion
      ref={this.tradesRef}
      duration={200}
      className="ion-padding accountSection trades"
      backgroundColor="#ffffff00"
      headerComponent={
        <AccountSectionHeader
          title="Trade Information"
          isExpanded={this.accordionController.isAccordionExpanded("trades")}
        />
      }
      expanded={this.accordionController.isAccordionExpanded("trades")}
      onClick={e => this.handleAccordionClick(e, "trades")}
    >
      <div className="flex column">
        <IonCard>
          <IonCardContent>
            <Observer>{() => {
              const trades = equifax.getTrades(this.data).filter(Boolean);
              return !isEmpty(trades) ? <>{trades.map(({ CNTrade }) => (CNTrade || [])[0] && (
                <IonList className="ion-no-padding" key={(CNTrade[0].AccountNumber || [])[0]}>
                  <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                    {(CNTrade[0].PortfolioType || [])[0].$.description}
                  </IonListHeader>
                  <div className="flex half ion-align-items-end">
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Creditor
                      </IonLabel>
                      <IonText>
                        {(CNTrade[0].CreditorId || [])[0].Name[0]}
                      </IonText>
                    </IonItem>
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Payment type
                      </IonLabel>
                      <IonText>
                        {((CNTrade[0].Narratives || [])[0].Narrative || [])[0].$.description}
                      </IonText>
                    </IonItem>
                  </div>
                  <div className="flex half ion-align-items-end">
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Account #
                      </IonLabel>
                      <IonText>
                        {(CNTrade[0].AccountNumber || [])[0]}
                      </IonText>
                    </IonItem>
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Account type
                      </IonLabel>
                      <IonText>
                        {(CNTrade[0].Association || [])[0].$.description}
                      </IonText>
                    </IonItem>
                  </div>
                  <div className="flex half ion-align-items-end">
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Date opened
                      </IonLabel>
                      <IonText>
                        {(CNTrade[0].DateOpened || [])[0]}
                      </IonText>
                    </IonItem>
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Date reported
                      </IonLabel>
                      <IonText>
                        {(CNTrade[0].DateReported || [])[0]}
                      </IonText>
                    </IonItem>
                  </div>
                  <div className="flex half ion-align-items-end">
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Balance
                      </IonLabel>
                      <IonText>
                        {getPriceString(Number((CNTrade[0].BalanceAmount || [])[0]), null, true)}
                      </IonText>
                    </IonItem>
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Highest reported amount / Limit
                      </IonLabel>
                      <IonText>
                        {getPriceString(Number((CNTrade[0].HighCreditAmount || [])[0]), null, true)}
                      </IonText>
                    </IonItem>
                  </div>
                  <div className="flex half ion-align-items-end">
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Term amount
                      </IonLabel>
                      <IonText>
                        {getPriceString(Number((CNTrade[0].PaymentTermAmount || [])[0]), null, true)}
                      </IonText>
                    </IonItem>
                    <IonItem lines="full">
                      <IonLabel position="stacked" className="textPrimary">
                        Status
                      </IonLabel>
                      <IonText>
                        {(CNTrade[0].PaymentRate || [])[0].$.description}
                      </IonText>
                    </IonItem>
                  </div>
                </IonList>
              ))}</> : <IonText className="ion-margin-vertical" color="primary">No trade information</IonText>
            }}</Observer>
          </IonCardContent>
        </IonCard>
      </div>
    </Accordion>
  )}</Observer>;

  renderLocalInquiries = () => <Observer>{() => (
    <Accordion
      ref={this.localInquiriesRef}
      duration={200}
      className="ion-padding accountSection localInquiries"
      backgroundColor="#ffffff00"
      headerComponent={
        <AccountSectionHeader
          title="Local Inquiries"
          isExpanded={this.accordionController.isAccordionExpanded("localInquiries")}
        />
      }
      expanded={this.accordionController.isAccordionExpanded("localInquiries")}
      onClick={e => this.handleAccordionClick(e, "localInquiries")}
    >
      <div className="flex column">
        <IonCard>
          <IonCardContent>
            <IonList className="ion-no-padding">
              <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                <div className="flex column transactionTable ion-padding-vertical">
                  <div className={`flex transactionTableRow`}>
                    <IonText className="textPrimary textBold">Date</IonText>
                    <IonText className="textPrimary textBold">Description</IonText>
                    <IonText className="textPrimary textBold">Phone number</IonText>
                  </div>
                  <Observer>{() => {
                    const inquiries = equifax.getLocalInq(this.data);
                    return !isEmpty(inquiries?.CNLocalInquiry) ? <>{
                      inquiries?.CNLocalInquiry.map((inq, i) => (
                        <div className={`flex transactionTableRow textDark`} key={i}>
                          <IonText>{inq.$.date}</IonText>
                          <IonText>{(inq.CustomerId[0]?.Name || [])[0] || "-"}</IonText>
                          <IonText>
                            {(((inq.CustomerId || [])[0]?.Telephone || [])[0]?.ParsedTelephone || [])[0]?.AreaCode}-
                            {(((inq.CustomerId || [])[0]?.Telephone || [])[0]?.ParsedTelephone || [])[0]?.Number}
                          </IonText>
                        </div>
                      ))
                    }</> : <IonText className="ion-margin-top ion-text-center">No inquiry records</IonText>
                  }}</Observer>
                </div>
              </IonListHeader>
            </IonList>
          </IonCardContent>
        </IonCard>
      </div>
    </Accordion>
  )}</Observer>;

  renderForeignInquiries = () => <Observer>{() => (
    <Accordion
      ref={this.foreignInquiriesRef}
      duration={200}
      className="ion-padding accountSection foreignInquiries"
      backgroundColor="#ffffff00"
      headerComponent={
        <AccountSectionHeader
          title="Foreign Inquiries"
          isExpanded={this.accordionController.isAccordionExpanded("foreignInquiries")}
        />
      }
      expanded={this.accordionController.isAccordionExpanded("foreignInquiries")}
      onClick={e => this.handleAccordionClick(e, "foreignInquiries")}
    >
      <div className="flex column">
        <IonCard>
          <IonCardContent>
            <IonList className="ion-no-padding">
              <IonListHeader className="flex ion-align-items-end textPrimary textBold">
                <div className="flex column transactionTable ion-padding-vertical">
                  <div className={`flex transactionTableRow`}>
                    <IonText className="textPrimary textBold">Date</IonText>
                    <IonText className="textPrimary textBold">Description</IonText>
                    <IonText className="textPrimary textBold">Detail</IonText>
                  </div>
                  <Observer>{() => {
                    const inquiries = equifax.getForeignInq(this.data);
                    return !isEmpty(inquiries?.CNForeignInquiry) ? <>{
                      inquiries?.CNForeignInquiry.map((inq, i) => (
                        <div className={`flex transactionTableRow textDark`} key={i}>
                          <IonText>{inq.$.date}</IonText>
                          <IonText>{(inq.InquiryProvince || [])[0] || "-"}</IonText>
                          <IonText>{(inq.CityNameOrInquiryNumber || [])[0] || "-"}</IonText>
                        </div>
                      ))
                    }</> : <IonText className="ion-margin-top ion-text-center">No inquiry records</IonText>
                  }}</Observer>
                </div>
              </IonListHeader>
            </IonList>
          </IonCardContent>
        </IonCard>
      </div>
    </Accordion>
  )}</Observer>;

  render() {
    return <IonPage className="creditReport">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="textSelectable">
            {this.clientName || this.id || ""}
          </IonTitle>
          <IonButtons slot="end">
            {/*<HeaderRefreshButton*/}
            {/*  loading={this.refreshing}*/}
            {/*  spinnerColor="secondary"*/}
            {/*  onRefresh={this.onRefresh}*/}
            {/*/>*/}
            <IonButton onClick={this.handleDelete}>
              <IonIcon slot="icon-only" icon={trashOutline} color="danger" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="textSelectable">
        {this.loading ? <FullScreenSpinner color="secondary" size={40} /> : (
          <div className="flex max column container ion-padding">
            {this.renderSummary()}
            <div className="flex column ion-align-items-center ion-margin-top ion-padding-bottom accountSections">
              {this.renderOverview()}
              {this.renderScores()}
              {this.renderEmployments()}
              {this.renderTradeAccounts()}
              {this.renderLocalInquiries()}
              {this.renderForeignInquiries()}
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  }
}

export default withIonLifeCycle(CreditReportPage);
