export default `<?xml version="1.0" encoding="UTF-8" ?>
<EfxTransmit xmlns="http://www.equifax.ca/XMLSchemas/EfxToCust" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
             xsi:schemaLocation="http://www.equifax.ca/XMLSchemas/EfxToCust http://www.equifax.ca/XMLSchemas/UAT/CNEfxTransmitToCust.xsd">
    <EfxReport requestNumber="1" reportId="CNCONSUMERCREDITFILE">
        <CNConsumerCreditReports>
            <CNConsumerCreditReport subjectType="SUBJECT" multipleNumber="1">
                <CNHeader>
                    <Request>
                        <Protocol>COND</Protocol>
                        <CustomerReferenceNumber>2495</CustomerReferenceNumber>
                        <CustomerNumber>999FS00444</CustomerNumber>
                        <LanguageIndicator>E</LanguageIndicator>
                        <InquiryType>I</InquiryType>
                        <OutputFormatCode>X</OutputFormatCode>
                    </Request>
                    <CreditFile>
                        <UniqueNumber>0037774502</UniqueNumber>
                        <FileSinceDate>2021-10-01</FileSinceDate>
                        <DateOfLastActivity>2022-05-10</DateOfLastActivity>
                        <DateOfRequest>2022-05-11</DateOfRequest>
                        <FileOwner>
                            <BureauCode>180</BureauCode>
                            <BureauContactInformation>Equifax inc.</BureauContactInformation>
                        </FileOwner>
                        <HitCode code="4" description="CONSUMER DECLARATION" />
                        <HitStrengthIndicator code="11" description="Regular hit" />
                    </CreditFile>
                    <Subject>
                        <SubjectName>
                            <LastName>TESTEVANS</LastName>
                            <FirstName>ALPHONSO</FirstName>
                        </SubjectName>
                        <SubjectId>
                            <DateOfBirth type="DATE">1977-03-02</DateOfBirth>
                        </SubjectId>
                    </Subject>
                </CNHeader>
                <CNAddresses>
                    <CNAddress code="CA" description="Current Address">
                        <DateReported>2017-01</DateReported>
                        <CivicNumber>150</CivicNumber>
                        <StreetName>GRAYDON HALL DR</StreetName>
                        <City code="TOR">TORONTO</City>
                        <Province code="ON" description="ONTARIO" />
                        <PostalCode>M3A3B3</PostalCode>
                        <UpdateSource code="S" description="Sts rptd" />
                    </CNAddress>
                </CNAddresses>
                <CNEmployments>
                    <CNEmployment code="ES" description="Subject&apos;sCurrent Employment">
                        <Occupation>1977/03/02</Occupation>
                    </CNEmployment>
                    <CNEmployment code="EF" description="Subject&apos;s Former Employment">
                        <Occupation>1977/03/02</Occupation>
                    </CNEmployment>
                    <CNEmployment code="E2" description="Subject&apos;s 2nd Former Employment">
                        <Occupation>1977/03/02</Occupation>
                    </CNEmployment>
                </CNEmployments>
                <CNTrades>
                    <CNTrade>
                        <DateReported>2022-03</DateReported>
                        <CreditorId>
                            <CustomerNumber>481FZ47657</CustomerNumber>
                            <Name>TD AUTO FINANCE CAN</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>800</AreaCode>
                                    <Number>832-3321</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CreditorId>
                        <AccountNumber>123567890</AccountNumber>
                        <Association code="J" description="Joint" />
                        <HighCreditAmount>000000007000</HighCreditAmount>
                        <PaymentTermAmount>000000000300</PaymentTermAmount>
                        <BalanceAmount>000000001047</BalanceAmount>
                        <MonthsReviewed>04</MonthsReviewed>
                        <DateOpened>2015-03</DateOpened>
                        <DateLastActivityOrPayment>2022-03</DateLastActivityOrPayment>
                        <PortfolioType code="I" description="Installment" />
                        <PaymentRate code="1" description="Paid as agreed and up to date" />
                        <Narratives>
                            <Narrative code="EX" description="Monthly payments" />
                        </Narratives>
                    </CNTrade>
                </CNTrades>
                <CNLocalInquiries>
                    <CNLocalInquiry date="2022-05-10">
                        <CustomerId>
                            <CustomerNumber>999MZ00103</CustomerNumber>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-10">
                        <CustomerId>
                            <CustomerNumber>999FS00444</CustomerNumber>
                            <Name>FINJOY INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>844</AreaCode>
                                    <Number>435-3934</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-10">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-09">
                        <CustomerId>
                            <CustomerNumber>999MZ00103</CustomerNumber>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-09">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-09">
                        <CustomerId>
                            <CustomerNumber>999FP00143</CustomerNumber>
                            <Name>WELLSFARGO</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>203</AreaCode>
                                    <Number>491-7198</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-07">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-06">
                        <CustomerId>
                            <CustomerNumber>999MZ00103</CustomerNumber>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-06">
                        <CustomerId>
                            <CustomerNumber>999ZZ03551</CustomerNumber>
                            <Name>KAIZENGAMING</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-06">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-06">
                        <CustomerId>
                            <CustomerNumber>999FS00312</CustomerNumber>
                            <Name>QUADFI INC</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>841-5262</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-06">
                        <CustomerId>
                            <CustomerNumber>999PQ00074</CustomerNumber>
                            <Name>PAYBRIGHT INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>710-7168</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-05">
                        <CustomerId>
                            <CustomerNumber>999FP00143</CustomerNumber>
                            <Name>WELLSFARGO</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>203</AreaCode>
                                    <Number>491-7198</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-05">
                        <CustomerId>
                            <CustomerNumber>999UE00140</CustomerNumber>
                            <Name>XOOMENERGY</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>704</AreaCode>
                                    <Number>763-4437</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-05">
                        <CustomerId>
                            <CustomerNumber>999FC02506</CustomerNumber>
                            <Name>UNI SIEGE SOCIAL</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>506</AreaCode>
                                    <Number>857-8120</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-05">
                        <CustomerId>
                            <CustomerNumber>999PQ00074</CustomerNumber>
                            <Name>PAYBRIGHT INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>710-7168</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-05">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-04">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-04">
                        <CustomerId>
                            <CustomerNumber>999FS00312</CustomerNumber>
                            <Name>QUADFI INC</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>841-5262</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-04">
                        <CustomerId>
                            <CustomerNumber>999PQ00074</CustomerNumber>
                            <Name>PAYBRIGHT INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>710-7168</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-03">
                        <CustomerId>
                            <CustomerNumber>999PQ00074</CustomerNumber>
                            <Name>PAYBRIGHT INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>710-7168</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-03">
                        <CustomerId>
                            <CustomerNumber>999FZ02774</CustomerNumber>
                            <Name>FUTURPRENEUR CANADA</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-03">
                        <CustomerId>
                            <CustomerNumber>999FS00312</CustomerNumber>
                            <Name>QUADFI INC</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>841-5262</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-03">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-02">
                        <CustomerId>
                            <CustomerNumber>999PQ00074</CustomerNumber>
                            <Name>PAYBRIGHT INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>710-7168</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-02">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999FZ02659</CustomerNumber>
                            <Name>PIVOTAL CAPITAL CORP</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>905</AreaCode>
                                    <Number>643-3034</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999PZ00115</CustomerNumber>
                            <Name>MODOHR TECHNOLOGIES</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>800</AreaCode>
                                    <Number>721-4248</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999FZ02931</CustomerNumber>
                            <Name>LM CREDIT INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>647</AreaCode>
                                    <Number>502-0648</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999FZ02873</CustomerNumber>
                            <Name>FUNDMORE.AI INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>613</AreaCode>
                                    <Number>277-7799</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999FZ02997</CustomerNumber>
                            <Name>TWO SHORES FINANCIAL</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>888</AreaCode>
                                    <Number>998-8269</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999AN00087</CustomerNumber>
                            <Name>SPRING AUTO FINANCE</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>778</AreaCode>
                                    <Number>379-2155</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999VF00047</CustomerNumber>
                            <Name>FARM CREDIT CORP</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999FP00143</CustomerNumber>
                            <Name>WELLSFARGO</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>203</AreaCode>
                                    <Number>491-7198</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999FZ03045</CustomerNumber>
                            <Name>WYZIA</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999HZ00022</CustomerNumber>
                            <Name>GE MONEY LOWE S LCC</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>780</AreaCode>
                                    <Number>990-2300</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999FZ03011</CustomerNumber>
                            <Name>LOANS CANADA</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>877</AreaCode>
                                    <Number>995-6269</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-05-01">
                        <CustomerId>
                            <CustomerNumber>999FM00897</CustomerNumber>
                            <Name>2782715 ONTARIO LTD</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>647</AreaCode>
                                    <Number>564-5614</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-30">
                        <CustomerId>
                            <CustomerNumber>999FZ03045</CustomerNumber>
                            <Name>WYZIA</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-30">
                        <CustomerId>
                            <CustomerNumber>999FM00897</CustomerNumber>
                            <Name>2782715 ONTARIO LTD</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>647</AreaCode>
                                    <Number>564-5614</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-30">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-30">
                        <CustomerId>
                            <CustomerNumber>999FP00143</CustomerNumber>
                            <Name>WELLSFARGO</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>203</AreaCode>
                                    <Number>491-7198</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-30">
                        <CustomerId>
                            <CustomerNumber>999FZ01701</CustomerNumber>
                            <Name>GE COMMERCIAL FIN</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>203</AreaCode>
                                    <Number>361-1738</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-30">
                        <CustomerId>
                            <CustomerNumber>999FZ02873</CustomerNumber>
                            <Name>FUNDMORE.AI INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>613</AreaCode>
                                    <Number>277-7799</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-30">
                        <CustomerId>
                            <CustomerNumber>999FZ02931</CustomerNumber>
                            <Name>LM CREDIT INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>647</AreaCode>
                                    <Number>502-0648</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-30">
                        <CustomerId>
                            <CustomerNumber>481ZZ01242</CustomerNumber>
                            <Name>EQUIFAX TESTING STS</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>800</AreaCode>
                                    <Number>387-9986</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-30">
                        <CustomerId>
                            <CustomerNumber>999PZ00115</CustomerNumber>
                            <Name>MODOHR TECHNOLOGIES</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>800</AreaCode>
                                    <Number>721-4248</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-30">
                        <CustomerId>
                            <CustomerNumber>999BB02788</CustomerNumber>
                            <Name>BANQUE LAURENTIENNE</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>514</AreaCode>
                                    <Number>284-4500</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999PQ00074</CustomerNumber>
                            <Name>PAYBRIGHT INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>710-7168</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>481ZZ01242</CustomerNumber>
                            <Name>EQUIFAX TESTING STS</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>800</AreaCode>
                                    <Number>387-9986</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999FZ02964</CustomerNumber>
                            <Name>FIDELITY INFORMATION</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>740</AreaCode>
                                    <Number>360-8699</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999ZZ03528</CustomerNumber>
                            <Name>GEOCOMPLY</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>604</AreaCode>
                                    <Number>336-0877</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999AZ00166</CustomerNumber>
                            <Name>DABADU INC</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>844</AreaCode>
                                    <Number>605-0101</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999FZ03011</CustomerNumber>
                            <Name>LOANS CANADA</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>877</AreaCode>
                                    <Number>995-6269</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999FZ02873</CustomerNumber>
                            <Name>FUNDMORE.AI INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>613</AreaCode>
                                    <Number>277-7799</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999FS00411</CustomerNumber>
                            <Name>MANZIL CAPITAL INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>301-1894</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999FP00200</CustomerNumber>
                            <Name>CLEAR FINANCIAL CORP</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>905</AreaCode>
                                    <Number>639-3173</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999VF00070</CustomerNumber>
                            <Name>FARMCREDIT</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>306</AreaCode>
                                    <Number>780-8900</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999FZ02931</CustomerNumber>
                            <Name>LM CREDIT INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>647</AreaCode>
                                    <Number>502-0648</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-29">
                        <CustomerId>
                            <CustomerNumber>999PZ00115</CustomerNumber>
                            <Name>MODOHR TECHNOLOGIES</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>800</AreaCode>
                                    <Number>721-4248</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-28">
                        <CustomerId>
                            <CustomerNumber>481ZZ01242</CustomerNumber>
                            <Name>EQUIFAX TESTING STS</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>800</AreaCode>
                                    <Number>387-9986</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-28">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-28">
                        <CustomerId>
                            <CustomerNumber>999FZ03045</CustomerNumber>
                            <Name>WYZIA</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-28">
                        <CustomerId>
                            <CustomerNumber>999FZ03011</CustomerNumber>
                            <Name>LOANS CANADA</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>877</AreaCode>
                                    <Number>995-6269</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-28">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-28">
                        <CustomerId>
                            <CustomerNumber>999BB02713</CustomerNumber>
                            <Name>ONDECKCAPI</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-28">
                        <CustomerId>
                            <CustomerNumber>999FS00411</CustomerNumber>
                            <Name>MANZIL CAPITAL INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>416</AreaCode>
                                    <Number>301-1894</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-28">
                        <CustomerId>
                            <CustomerNumber>999BB01053</CustomerNumber>
                            <Name>CANADIAN WESTERN BK</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>780</AreaCode>
                                    <Number>423-8875</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-28">
                        <CustomerId>
                            <CustomerNumber>999ZS00316</CustomerNumber>
                            <Name>ACCES CREDIT</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>450</AreaCode>
                                    <Number>696-1000</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999FZ02774</CustomerNumber>
                            <Name>FUTURPRENEUR CANADA</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999ZZ03420</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999ZZ03528</CustomerNumber>
                            <Name>GEOCOMPLY</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>604</AreaCode>
                                    <Number>336-0877</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999AZ00166</CustomerNumber>
                            <Name>DABADU INC</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>844</AreaCode>
                                    <Number>605-0101</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999FM00897</CustomerNumber>
                            <Name>2782715 ONTARIO LTD</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>647</AreaCode>
                                    <Number>564-5614</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999FZ02873</CustomerNumber>
                            <Name>FUNDMORE.AI INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>613</AreaCode>
                                    <Number>277-7799</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999FZ03011</CustomerNumber>
                            <Name>LOANS CANADA</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>877</AreaCode>
                                    <Number>995-6269</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999ZZ03437</CustomerNumber>
                            <Name>ILABS</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>514</AreaCode>
                                    <Number>387-0638</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999FZ03045</CustomerNumber>
                            <Name>WYZIA</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999UE00199</CustomerNumber>
                            <Name>LENDHUB</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>647</AreaCode>
                                    <Number>896-9337</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999FZ02584</CustomerNumber>
                            <Name>VARIONCAPI</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-27">
                        <CustomerId>
                            <CustomerNumber>999FA00776</CustomerNumber>
                            <Name>ASKAVA INC</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>613</AreaCode>
                                    <Number>612-7599</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>999FZ02774</CustomerNumber>
                            <Name>FUTURPRENEUR CANADA</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>999BB01681</CustomerNumber>
                            <Name>CAPONE BANK AML</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>800</AreaCode>
                                    <Number>481-3239</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>999UZ00152</CustomerNumber>
                            <Name>WAVEDIRECT</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>226</AreaCode>
                                    <Number>348-8985</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>999ZZ03412</CustomerNumber>
                            <Name>TRUNARRATIVE</Name>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>999FP00143</CustomerNumber>
                            <Name>WELLSFARGO</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>203</AreaCode>
                                    <Number>491-7198</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>999FZ02873</CustomerNumber>
                            <Name>FUNDMORE.AI INC.</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>613</AreaCode>
                                    <Number>277-7799</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>481ZZ01242</CustomerNumber>
                            <Name>EQUIFAX TESTING STS</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>800</AreaCode>
                                    <Number>387-9986</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>999FP00200</CustomerNumber>
                            <Name>CLEAR FINANCIAL CORP</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>905</AreaCode>
                                    <Number>639-3173</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>001ZZ21015</CustomerNumber>
                            <Name>QA TEST BEVERLEYHIND</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>514</AreaCode>
                                    <Number>493-2376</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>999FM00781</CustomerNumber>
                            <Name>PINCHFINAN</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>657</AreaCode>
                                    <Number>267-8091</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                    <CNLocalInquiry date="2022-04-26">
                        <CustomerId>
                            <CustomerNumber>999FM00856</CustomerNumber>
                            <Name>COAST CAPITAL SAVING</Name>
                            <Telephone>
                                <ParsedTelephone>
                                    <AreaCode>604</AreaCode>
                                    <Number>953-4427</Number>
                                </ParsedTelephone>
                            </Telephone>
                        </CustomerId>
                    </CNLocalInquiry>
                </CNLocalInquiries>
                <CNForeignInquiries>
                    <CNForeignInquiry date="2022-03-11">
                        <CityNameOrInquiryNumber>INTLUSA 999ZS00449</CityNameOrInquiryNumber>
                        <InquiryProvince>MICROBILT</InquiryProvince>
                    </CNForeignInquiry>
                </CNForeignInquiries>
                <CNConsumerStatements>
                    <CNConsumerStatement>
                        <DateReported>2022-02</DateReported>
                        <DateToBePurged>2028-05</DateToBePurged>
                        <Statement>**** WARNING ****ALERT TO VERIFY CONSUMER&apos;S IDENTITY - PLEASE CONTACT CONSUMER T
                            (000) - 000-0000 BEFORE EXTENDING CREDIT</Statement>
                    </CNConsumerStatement>
                </CNConsumerStatements>
                <CNScores>
                    <CNScore productType="SCBS" productId="30129" description="FICO">
                        <VersionNumber>010</VersionNumber>
                        <ModelId>A</ModelId>
                        <ScoreNumber>1</ScoreNumber>
                        <ScoreIndicator>S</ScoreIndicator>
                        <Result>
                            <Value>00686</Value>
                        </Result>
                        <Reasons>
                            <Reason code="00031" description="Too few accounts with recent payment information." />
                            <Reason code="00014" description="Length of time accounts have been established." />
                            <Reason code="00046" description="Lack of recent non-LOC revolving account info." />
                            <Reason code="00015" description="LacK of recent banK/national revolving information." />
                        </Reasons>
                    </CNScore>
                    <CNScore productType="SCBS" productId="30231" description="Score Complete 1.0">
                        <VersionNumber>010</VersionNumber>
                        <ModelId>A</ModelId>
                        <ScoreNumber>1</ScoreNumber>
                        <ScoreIndicator>S</ScoreIndicator>
                        <Result>
                            <Value>00723</Value>
                        </Result>
                        <Reasons>
                            <Reason code="00077" description="Recent inquiry" />
                            <Reason code="00073"
                                    description="Too many recent auto, sales finance, national cards or other inquiries" />
                            <Reason code="00076"
                                    description="Too many auto, sales finance, national cards or other inquiries" />
                            <Reason code="00095" description="Clean and Thin" />
                        </Reasons>
                    </CNScore>
                </CNScores>
            </CNConsumerCreditReport>
        </CNConsumerCreditReports>
    </EfxReport>
</EfxTransmit>
`;
