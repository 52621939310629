import React from "react";
import { IonContent, IonPage, IonText, withIonLifeCycle } from "@ionic/react";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";

export interface HomeProps extends RouteComponentProps<{
}> {}

@observer
class Home extends React.Component<HomeProps> {
  render() {
    return <IonPage>
      <IonContent fullscreen>
        <div className="flex max container ion-justify-content-center ion-align-items-center ion-padding">
          <IonText className="font-l ion-text-center" color="medium">
            Welcome! Please choose an Equifax credit report entry from the sidebar to begin with.
          </IonText>
        </div>
      </IonContent>
    </IonPage>;
  }
}

export default withIonLifeCycle(Home)
