import { observer } from "mobx-react";
import { IonItem, IonText } from "@ionic/react";
import * as React from "react";
import { CreditReport } from "../../lib/types/dataTypes";
import "./styles.css";
import { equifax } from "../../client/equifax";

export interface EquifaxItemProps {
  item: CreditReport;
}

const EquifaxItem: React.FC<EquifaxItemProps> = observer(
  ({ item }) => {
    const id = equifax.getId(item);
    const clientName = equifax.getClientName(item);
    const date = equifax.getReportDate(item);
    return <IonItem
      lines="full"
      className="equifaxItem"
      routerLink={`/${id}`}
    >
      <div className="flex column">
        <div className="flex ion-justify-content-between">
          <IonText className={`font-s ${!clientName ? "textItalic textMedium" : ""}`}>{clientName || "Unknown client name"}</IonText>
          <IonText color="medium" className="font-xs">App ID:&nbsp;{"XXXXX"}</IonText>
        </div>
        <IonText color="medium" className="font-xs">Created:&nbsp;{date}</IonText>
        <IonText color="medium" className="font-xs">{id}</IonText>
      </div>
    </IonItem>
  });

export default EquifaxItem;
