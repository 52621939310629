import * as React from "react";
import { observer, Observer } from "mobx-react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonList,
  IonListHeader,
  IonMenu,
  IonRippleEffect,
  IonSearchbar,
  IonSpinner,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";
import "./styles.css";
import { logo } from "../../config/constants";
import { CreditReport } from "../../lib/types/dataTypes";
import { addOutline, refreshCircleOutline } from "ionicons/icons";
import FullScreenSpinner from "../FullScreenSpinner";
import EquifaxItem from "../EquifaxItem";
import { equifax } from "../../client/equifax";
import { ui } from "../../client/ui";

interface SidebarProps extends RouteComponentProps {
  disabled: boolean;
  loading: boolean;
  loadingMore: boolean;
  loadingAll: boolean;
  creditReports: CreditReport[];
  searchValue: string;
  onSearchChange: (event: any) => void;
  onRefresh?: (event: any) => void;
  onGetAll?: (event: any) => void;
  onGetMore?: (event: any) => void;
}

const handleManualPull = (event: any, history: RouteComponentProps["history"]) => {
  const handler = (results: any) => {
    const [
      FirstName,
      LastName,
      DateOfBirth,
      SIN,
      CivicNumber,
      StreetName,
      City,
      ProvinceCode,
      PostalCode
    ] = Object.values(results || {});
    return equifax.fetchManualReport({
      FirstName,
      LastName,
      DateOfBirth,
      SIN,
      CivicNumber,
      StreetName,
      City,
      ProvinceCode,
      PostalCode,
      reference: "manual_pull"
    })
    .then(id => id && history.push(`/${id}`));
  };
  const {
    FirstName,
    LastName,
    DateOfBirth,
    SIN,
    CivicNumber,
    StreetName,
    City,
    ProvinceCode,
    PostalCode
  } = equifax.lastManualPullEntry;
  return ui.alert({
    header: "Manual pull credit report",
    message: "Please enter customer detail",
    inputs: [
      { placeholder: "First name", value: FirstName },
      { placeholder: "Last name", value: LastName },
      { placeholder: "Date of birth (YYYY-MM-DD)", value: DateOfBirth },
      { placeholder: "SIN (Optional)", value: SIN },
      { placeholder: "Street number", value: CivicNumber },
      { placeholder: "Street name", value: StreetName },
      { placeholder: "City", value: City },
      { placeholder: "Province code", value: ProvinceCode || "BC" },
      { placeholder: "Postal code", value: PostalCode }
    ],
    buttons: [
      {
        role: "cancel",
        text: "Cancel",
        cssClass: "textDark"
      },
      {
        text: "Pull",
        handler
      }
    ]
  })
}

const filterCreditReports = (items: CreditReport[], match: string) => {
  const filter = (item: CreditReport) => {
    match = match && match.toString().trim();
    // const regExp = new RegExp(match, "i");
    // return (inverite.referenceToAppId(item.inveriteId) && (inverite.referenceToAppId(item.referenceId)).toString().match(regExp)) ||
    //   (item.clientName && item.clientName.match(regExp)) ||
    //   (item.inveriteId && item.inveriteId.match(regExp)) ||
    //   (item.appId && item.appId.toString().match(regExp)) ||
    //   (item.referenceId && item.referenceId.match(regExp));
    return false;
  };
  return items.filter(filter);
};

const renderCreditReports = (list: CreditReport[]) => {
  const groups: { date: string, items: CreditReport[] }[] = [];
  for (const item of list) {
    const date = equifax.getReportDate(item);
    const group = groups.find(g => g.date === date);
    if (!group) {
      groups.push({
        date,
        items: [item]
      });
    } else {
      group.items.push(item);
    }
  }
  return <Observer>{() => <>
    {groups
    .sort((a, b) => (
      new Date(a.date) < new Date(b.date) ? 1 : -1
    ))
    .map(group => <div key={group.date}>
      <IonItemDivider color="light" className="textPrimary">
        {group.date}
      </IonItemDivider>
      {group.items.map(item => {
        const id = equifax.getId(item);
        return <EquifaxItem key={id} item={item}/>;
      })}
    </div>)}
  </>}</Observer>;
};

const Sidebar: React.FC<SidebarProps> = observer(
  ({
     disabled,
     loading,
     loadingMore,
     loadingAll,
     history,
     creditReports,
     searchValue,
     onSearchChange,
     onRefresh,
     onGetAll,
     onGetMore
   }) => (
    <IonMenu type="reveal" contentId="main" menuId="main" className="sidebar" disabled={disabled}>
      <IonHeader translucent>
        <IonToolbar color="primary" onClick={() => history.push("/")} className="ion-activatable relative">
          <IonThumbnail slot="start" className="ion-margin-start">
            <IonImg src={logo} />
          </IonThumbnail>
          <IonTitle className="ion-no-padding ion-margin-start textBold">
            Finjoy Equifax Data
          </IonTitle>
          <IonRippleEffect />
        </IonToolbar>
      </IonHeader>
      <IonSearchbar
        className="bar ion-no-padding"
        debounce={100}
        value={searchValue}
        onIonChange={onSearchChange}
        placeholder="App ID, External ID, Client Names..."
      />
      <IonContent>
        <IonList className="ion-no-padding">
          <IonListHeader className="flex ion-justify-content-between ion-padding-end">
            <IonText>
              Recent credit reports
            </IonText>
            <IonButtons>
              <IonButton
                shape="round"
                onClick={onRefresh}
              >
                <IonIcon
                  slot="icon-only"
                  icon={refreshCircleOutline}
                  color="primary"
                />
              </IonButton>
            </IonButtons>
          </IonListHeader>
          <IonItem button lines="full" onClick={e => handleManualPull(e, history)}>
            <IonIcon slot="start" icon={addOutline} color="primary" />
            <IonText color="primary">
              Manually pull credit report
            </IonText>
          </IonItem>
          {!loading && renderCreditReports(searchValue ? filterCreditReports(creditReports, searchValue) : creditReports)}
          {!loading && !searchValue && <IonItem button lines="full" onClick={onGetMore}>
            {loadingMore ? <IonSpinner color="secondary" /> : <IonText color="primary">Get more...</IonText>}
          </IonItem>}
          {!loading && searchValue && <IonItem button lines="full" onClick={onGetAll}>
            <IonText color="primary">{loadingAll ? (
              <div className="flex ion-align-items-center">
                <IonSpinner color="secondary" />
                <IonText className="ion-margin-start">Loading from server, this may take a while...</IonText>
              </div>
            ) : "Search more on server..."}</IonText>
          </IonItem>}
        </IonList>
        {loading && <FullScreenSpinner color="secondary" size={24} />}
      </IonContent>
    </IonMenu>
  ));

export default withRouter(Sidebar);
